import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const JotForm = ({ formId, formTitle, formUrl }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          `iframe[id='JotFormIFrame-${formId}']`,
          'https://form.jotform.com/'
        );
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [formId]);

  // Function to handle back button click
  const handleBackClick = () => {
    navigate('/dashboard'); // Navigate back to Forms
  };

  // Append parameters to the form URL if needed
  if (formUrl !== 'https://form.jotform.com/241275195641155') {
    const nixformid = formId;
    const userid = location.state.uId;

    const appendParamsToUrl = (url, params) => {
      const urlObj = new URL(url);
      Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]));
      return urlObj.toString();
    };

    formUrl = appendParamsToUrl(formUrl, { nixformid, userid });
  }

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-6">
      {/* Updated Go Back button position */}
      {location.pathname !== '/docs/contact' && (
        <div className="max-w-7xl mx-auto mb-4">
          <button
            className="flex items-center justify-start bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 rounded-lg shadow-md transition duration-300"
            onClick={handleBackClick}
          >
            ← Go Back
          </button>
        </div>
      )}
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg p-6">
        <iframe
          id={`JotFormIFrame-${formId}`}
          title={formTitle}
          onLoad={() => window.parent.scrollTo(0, 0)}
          src={formUrl}
          frameBorder="0"
          className="w-full h-[870px] border-0 rounded-lg"
          scrolling="no"
        />
      </div>
    </div>
  );
};

export default JotForm;
