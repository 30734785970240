import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const Pricing = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      easing: 'ease-in-out', // Easing style
      once: true, // Animation should happen only once
    });
  }, []);

  return (
    <div className="bg-gray-100 py-16">
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title and Intro */}
        <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-800 mb-8" data-aos="zoom-in">
          Pricing Plans
        </h2>
        <p className="text-lg md:text-xl text-center text-gray-600 mb-12 max-w-3xl mx-auto" data-aos="zoom-in">
          Choose the best plan that fits your needs and ensures compliance and reporting.
        </p>

        {/* Device Pricing Section */}
        <div className="bg-white rounded-lg shadow-lg p-8 text-center mb-12" data-aos="zoom-in">
          <p className="text-2xl font-bold text-gray-800 mb-4">The Device</p>
          <h4 className="text-4xl font-extrabold text-yellow-500 mb-2">$50<span className="text-lg font-normal">/vehicle</span></h4>
          <p className="text-gray-600">One-time purchase, lifetime support.</p>
        </div>

        <h2 className="text-4xl text-center text-gray-600 mb-12">+</h2>

        {/* Pricing Tiers Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Tier 1 */}
          <div
            className="bg-white rounded-lg shadow-lg p-8 text-center transition-transform transform hover:scale-105"
            data-aos="fade-up"
          >
            <p className="text-2xl font-bold text-gray-800 mb-4">Tier 1</p>
            <h4 className="text-4xl font-extrabold text-yellow-500 mb-2">$15<span className="text-lg font-normal">/month</span></h4>
            <p className="text-gray-600 mb-4">Basic reporting and data collection.</p>
          </div>

          {/* Tier 2 */}
          <div
            className="bg-white rounded-lg shadow-lg p-8 text-center transition-transform transform hover:scale-105"
            data-aos="fade-up"
          >
            <p className="text-2xl font-bold text-gray-800 mb-4">Tier 2</p>
            <h4 className="text-4xl font-extrabold text-yellow-500 mb-2">$25<span className="text-lg font-normal">/month</span></h4>
            <p className="text-gray-600 mb-4">Customized reports and advanced data collection.</p>
          </div>

          {/* Tier 3 */}
          <div
            className="bg-white rounded-lg shadow-lg p-8 text-center transition-transform transform hover:scale-105"
            data-aos="fade-up"
          >
            <p className="text-2xl font-bold text-gray-800 mb-4">Tier 3</p>
            <h4 className="text-4xl font-extrabold text-yellow-500 mb-2">$45<span className="text-lg font-normal">/month</span></h4>
            <p className="text-gray-600 mb-4">Tier 2 features + custom integration and priority support.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
