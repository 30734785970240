import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import { useUser } from '../App'; // Import the custom hook from App.js

function Footer() {
  const { user } = useUser(); // Get the user from context

  // If user exists, do not render the footer
  if (user) {
    return null;
  }

  return (
    <footer className="bg-gray-800 text-gray-300 py-6 px-4">
      {/* Desktop Footer */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 justify-center">
      <div className="footer-section min-w-[200px]">
          <h4 className="footer-heading text-sm font-semibold text-gray-200 mb-4">What We Offer</h4>
          <ul>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">Vehicle Emissions</Link></li>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">Regulatory Compliance</Link></li>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">Third Party Integration</Link></li>
          </ul>
        </div>

        <div className="footer-section min-w-[200px]">
          <h4 className="footer-heading text-sm font-semibold text-gray-200 mb-4">About</h4>
          <ul>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">About Us</Link></li>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">Testimonials</Link></li>
          </ul>
        </div>

        <div className="footer-section min-w-[200px]">
          <h4 className="footer-heading text-sm font-semibold text-gray-200 mb-4">Feedback</h4>
          <ul>
            <li className="mb-2"><Link to="/underConstruction" className="text-gray-500 hover:text-yellow-500">Send Feedback</Link></li>
          </ul>
        </div>

        <div className="footer-section min-w-[200px]">
          <h4 className="footer-heading text-sm font-semibold text-gray-200 mb-4">Help & Support</h4>
          <ul>
            <li className="mb-2"><Link to="/docs/contact" className="text-gray-500 hover:text-yellow-500">Contact Us</Link></li>
            <li className="flex space-x-4 mt-4">
              <a href="https://drive.google.com/file/d/1AYNlEuyBmBSNqh-vnAl9pr8JVCOaO2mb/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-yellow-500">
                <i className="fab fa-android text-2xl"></i>
              </a>
              <a href="https://drive.google.com/file/d/1dyKhjiZqsY1JwRVyyYxj2TgE2ixDf2Mr/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-yellow-500">
                <i className="fab fa-apple text-2xl"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Mobile Footer */}
      <div className="mt-8 md:hidden bg-gray-900 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h4 className="text-lg font-semibold text-gray-100 mb-4">Explore Nix Solutions</h4>
          <div className="flex flex-wrap justify-center space-x-4 mb-6 text-gray-300">
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">Vehicle Emissions</Link>
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">Regulatory</Link>
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">Third Party Integration</Link>
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">About Us</Link>
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">Testimonials</Link>
            <Link to="/underConstruction" className="hover:text-yellow-500 mb-2">Send Feedback</Link>
            <Link to="/docs/contact" className="hover:text-yellow-500 mb-2">Contact Us</Link>
          </div>

          <h4 className="text-lg font-semibold text-gray-100 mb-4">Stay Connected</h4>
          <div className="flex justify-center space-x-4 mb-6 text-gray-300">
            <a href="https://www.facebook.com/people/Nixcom/61556918924668/" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-500">
              <i className="fab fa-facebook text-2xl"></i>
            </a>
            <a href="https://www.linkedin.com/company/100918420" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-500">
              <i className="fab fa-linkedin text-2xl"></i>
            </a>
          </div>

          <h4 className="text-lg font-semibold text-gray-100 mb-4">Download Our App</h4>
          <div className="flex justify-center space-x-4 mb-6 text-gray-300">
            <a href="https://drive.google.com/file/d/1AYNlEuyBmBSNqh-vnAl9pr8JVCOaO2mb/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-500">
              <i className="fab fa-android text-2xl"></i>
            </a>
            <a href="https://drive.google.com/file/d/1dyKhjiZqsY1JwRVyyYxj2TgE2ixDf2Mr/view?usp=sharing" target="_blank" rel="noopener noreferrer" className="hover:text-yellow-500">
              <i className="fab fa-apple text-2xl"></i>
            </a>
          </div>

          <div className="mt-8 text-sm text-gray-500">
            <p>© 2024 Nix Solutions. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
