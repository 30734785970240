import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 800 });
  }, []);

  return (
    <div className="services-section py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-5xl font-bold text-gray-800 mb-16 text-center">Our Services</h2>

        {/* Service 1 */}
        <div className="service-item flex justify-center flex-col md:flex-row items-center mb-16" data-aos="fade-up">
          <div className="service-text md:w-3/4 md:pr-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Streamline Emission Monitoring</h3>
            <p className="text-gray-600 mb-4">
              Our real-time sensors provide comprehensive data to help you monitor and reduce vehicle emissions. Optimize your fleet's performance while complying with environmental standards.
            </p>
          </div>
          <div className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
            <img src="/images/emission-monitor.jpg" alt="Emission Monitoring" className="w-full h-auto rounded-lg shadow-md" />
          </div>
        </div>

        {/* Service 2 */}
        <div className="service-item flex justify-center flex-col md:flex-row items-center mb-16" data-aos="fade-up" data-aos-delay="50">
          <div className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
            <img src="/images/third-party2.jpg" alt="Regulatory Reports" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="service-text md:w-3/4 md:pl-12 text-right">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Automated Regulatory Reports</h3>
            <p className="text-gray-600 mb-4">
              Easily generate reports that meet regulatory compliance. Our system automatically compiles all the necessary data, so you can focus on your business.
            </p>
          </div>
        </div>

        {/* Service 3 */}
        <div className="service-item flex justify-center flex-col md:flex-row items-center mb-16" data-aos="fade-up" data-aos-delay="50">
          <div className="service-text md:w-3/4 md:pr-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Seamless Third-Party Integration</h3>
            <p className="text-gray-600 mb-4">
              Integrate NIX with your existing systems. We ensure smooth connectivity with third-party solutions to make your operations more efficient.
            </p>
          </div>
          <div className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
            <img src="/images/register.jpeg" alt="Third-Party Integration" className="w-full h-auto rounded-lg shadow-md" />
          </div>
        </div>

        {/* Service 4 */}
        <div className="service-item flex justify-center flex-col md:flex-row items-center mb-16" data-aos="fade-up" data-aos-delay="50">
          <div className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
            <img src="/images/data-analytics.jpg" alt="Data Analytics" className="w-full h-auto rounded-lg shadow-md" />
          </div>
          <div className="service-text md:w-3/4 md:pl-12 text-right">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Advanced Data Analytics</h3>
            <p className="text-gray-600 mb-4">
              Leverage our data analytics to gain insights into your operations. Make data-driven decisions to enhance productivity and profitability.
            </p>
          </div>
        </div>

        {/* Service 5 */}
        <div className="service-item flex justify-center flex-col md:flex-row items-center mb-16" data-aos="fade-up" data-aos-delay="50">
          <div className="service-text md:w-3/4 md:pr-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-4">Real-Time Fleet Management</h3>
            <p className="text-gray-600 mb-4">
              Manage your fleet in real-time with our integrated solutions. Monitor location, performance, and maintenance to keep your fleet running smoothly.
            </p>
          </div>
          <div className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105">
            <img src="/images/management.jpeg" alt="Fleet Management" className="w-full h-auto rounded-lg shadow-md" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
