import React, { useState, useEffect } from 'react';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const AddUserModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useUser();
  const token = user.token;
  const companyId = user.companyId;
  const secretKey = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef');

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const displaydefpass = CryptoJS.AES.decrypt(user.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setUserName('');
      setPassword(displaydefpass);
      setEmail('');
      setRole('');
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setFormDisabled(false);
    }
  }, [isOpen, displaydefpass]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'userName') setUserName(value);
    if (name === 'password') setPassword(value);
    if (name === 'email') setEmail(value);
    if (name === 'role') setRole(value);
    if (name === 'firstName') setFirstName(value);
    if (name === 'middleName') setMiddleName(value);
    if (name === 'lastName') setLastName(value);
  }

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) throw json;
        return json;
      } catch (error) {
        const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error saving user. Please try again.';
        throw new Error(errorMessage);
      }
    });
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setFormDisabled(true);

    const encryptedPassword = CryptoJS.SHA256(password).toString();

    const newUser = { userName, password: encryptedPassword, email, role, companyId, firstName, middleName, lastName };
    try {
      const res = await fetchPostApi('/user/adduser', newUser);
      
      if (res.status === 'success') {
        const maildata = { 
          userName, 
          password: user.companyDefPass, 
          email, 
          role, 
          companyId, 
          firstName, 
          lastName,
          addedByFirstName: user.firstName, 
          addedByLastName: user.lastName 
        };

        try {
          const mailres = await fetchPostApi('/user/sendEmail', maildata);

          if (mailres.status === 'success') {
            Swal.fire({
              title: 'Success!',
              text: 'User created and email sent successfully.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              setLoading(false);
              onSave();
              onClose();
            });
          } else {
            throw new Error('Email was not sent.');
          }
        } catch (emailError) {
          Swal.fire({
            title: 'Warning!',
            text: 'User created, but the email was not sent.',
            icon: 'warning',
            confirmButtonText: 'OK'
          }).then(() => {
            setLoading(false);
            onSave();
            onClose();
          });
        }
      } else {
        throw new Error('Error creating user.');
      }
    } catch (userError) {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: userError.message || 'Error saving user. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
    }
    setLoading(false);
  }

  if (!isOpen) return null;

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
        </div>
      )}

<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Add User</h2>
        <form onSubmit={handleSubmit} className="space-y-4 scrollbehaviour">
            <div className="form-group">
              <label htmlFor="firstName" className="block text-gray-700">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter first name"
                value={firstName}
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="middleName" className="block text-gray-700">Middle Name</label>
              <input
                type="text"
                id="middleName"
                name="middleName"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter middle name"
                value={middleName}
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="block text-gray-700">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter last name"
                value={lastName}
                onChange={handleChange}
                disabled={formDisabled}
              />
            </div>
            <div className="form-group">
              <label htmlFor="userName" className="block text-gray-700">Username <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="userName"
                name="userName"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter username"
                value={userName}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="block text-gray-700">Password <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="password"
                name="password"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 bg-gray-200"
                placeholder="Enter password"
                value={password}
                disabled
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email" className="block text-gray-700">Email Address <span className="text-red-500">*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter email"
                value={email}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="role" className="block text-gray-700">Role <span className="text-red-500">*</span></label>
              <select
                id="role"
                name="role"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                value={role}
                onChange={handleChange}
                disabled={formDisabled}
                required
              >
                <option value="">Select</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                disabled={formDisabled}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                disabled={formDisabled}
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddUserModal;
