import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import config from '../config';

const AddJotFormModal = ({ isOpen, onClose, onSave }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.token;
  const companyId = user?.companyId;
  const createdBy = user?.userId;

  const [formName, setFormName] = useState('');
  const [formLink, setFormLink] = useState('');
  const [isActive, setIsActive] = useState('');
  const [hasMobileNotify, setHasMobileNotify] = useState('');
  const [hasEmailNotify, setHasEmailNotify] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormName('');
      setFormLink('');
      setIsActive('');
      setHasMobileNotify('');
      setHasEmailNotify('');
      setFormDisabled(false);
    }
  }, [isOpen]);

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}${endpoint}`, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) throw json;
        return json;
      } catch (error) {
        if (!res.ok) {
          const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error creating form. Please try again.';
          throw new Error(errorMessage);
        }
        throw error;
      }
    });
  };

  const handleFormLinkBlur = () => {
    const validLinkPattern = /^https:\/\//;
    if (formLink === ""){
        Swal.fire({
            title: 'Missing Link!',
            text: 'The Form link field cannot be empty',
            icon: 'error',
            confirmButtonText: 'OK' 
        });
    }
    else if (!validLinkPattern.test(formLink)) {
      Swal.fire({
        title: 'Invalid Link!',
        text: 'The Form link must start with https://',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setLoading(true);

    const formData = {
      formName,
      formLink,
      isActive: isActive === 'Yes',
      hasMobileNotify: hasMobileNotify === 'Yes',
      hasEmailNotify: hasEmailNotify === 'Yes',
      companyId,
      createdBy
    };

    try {
      const res = await fetchPostApi('/form/createForm', formData);

      if (res.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: 'Form created successfully.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          setLoading(false);
          onSave();
          onClose();
        });
      } else {
        throw new Error('Error creating form.');
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: error.message || 'Error creating form. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
        </div>
      )}

<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Add a New Form</h2>
         <form onSubmit={handleSubmit} className="space-y-4">
            <div className="form-group">
              <label htmlFor="formName" className="block text-gray-700">Form Name <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="formName"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter JotForm Name"
                value={formName}
                onChange={(e) => setFormName(e.target.value)}
                disabled={formDisabled}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="formLink" className="block text-gray-700">Form Link <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="formLink"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                placeholder="Enter JotForm Link : https://"
                value={formLink}
                onChange={(e) => setFormLink(e.target.value)}
                onBlur={handleFormLinkBlur}
                disabled={formDisabled}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="isActive" className="block text-gray-700">Is Active <span className="text-red-500">*</span></label>
              <select
                id="isActive"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                disabled={formDisabled}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="hasMobileNotify" className="block text-gray-700">Has Mobile Notify <span className="text-red-500">*</span></label>
              <select
                id="hasMobileNotify"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                value={hasMobileNotify}
                onChange={(e) => setHasMobileNotify(e.target.value)}
                disabled={formDisabled}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="hasEmailNotify" className="block text-gray-700">Has Email Notify <span className="text-red-500">*</span></label>
              <select
                id="hasEmailNotify"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                value={hasEmailNotify}
                onChange={(e) => setHasEmailNotify(e.target.value)}
                disabled={formDisabled}
                required
              >
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                disabled={formDisabled}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                disabled={formDisabled}
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddJotFormModal;
