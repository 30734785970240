import React, { useState, useEffect } from 'react';
import config from '../config';
import Swal from 'sweetalert2';
import { useUser } from '../App';
import CryptoJS from 'crypto-js'; // for resetting to default password by admin

const EditUserModal = ({ isOpen, onClose, onSave, user }) => {
  const { user: currentUser } = useUser();
  const token = currentUser.token;
  const secretKey = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef');

  const [userId, setUserId] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [defPassword, setDefPassword] = useState(null);
  const companyDefaultPassword = CryptoJS.AES.decrypt(currentUser.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setUserId(user.id);
      setIsActive(user.isActive === 'Yes' ? 'Yes' : 'No');
      setDefPassword(companyDefaultPassword);
      if (!isOpen) {
        setIsResetPassword(false);
      }
    }
  }, [user, isOpen, companyDefaultPassword]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      userId: userId,
      status: isActive === 'Yes'
    };

    const response = await fetch(`${config.API_BASE_URL}/user/editUser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      setLoading(false);
      const res = await response.json();
      Swal.fire({
        title: 'Success!',
        text: 'User status updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      onSave({ ...user, isActive: isActive === 'Yes' });
      onClose();
    } else {
      setLoading(false);
      const errorRes = await response.json();
      Swal.fire({
        title: 'Error!',
        text: `Error updating user status: ${errorRes.data || 'Please try again.'}`,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleResetPassword = async (e) => {
    setLoading(true);
    e.preventDefault();

    const payload = {
      userId: userId,
      companyDefaultPassword: CryptoJS.SHA256(defPassword).toString()
    };

    try {
      const response = await fetch(`${config.API_BASE_URL}/user/adminResetPassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const decryptedPassword = CryptoJS.AES.decrypt(currentUser.companyDefPass, secretKey, { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
        const maildata = {
          userName: user.userName,
          email: user.email,
          role: user.role,
          companyId: user.companyId,
          firstName: user.firstName,
          lastName: user.lastName,
          password: currentUser.companyDefPass,
          addedByFirstName: currentUser.firstName,
          addedByLastName: currentUser.lastName,
          action: 'resetPassword'
        };

        try {
          const mailres = await fetch(`${config.API_BASE_URL}/user/sendEmail`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(maildata),
          });

          if (mailres.ok) {
            Swal.fire({
              title: 'Success!',
              text: `Successfully reset to default password: ${decryptedPassword} and email sent!`,
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(() => {
              onClose();
            });
          } else {
            throw new Error('The email was not sent.');
          }
        } catch (emailError) {
          Swal.fire({
            title: 'Warning!',
            text: `Successfully reset to default password: ${decryptedPassword}, but the email was not sent.`,
            icon: 'warning',
            confirmButtonText: 'OK'
          }).then(() => {
            onClose();
          });
        }
      } else {
        const errorRes = await response.json();
        Swal.fire({
          title: 'Error!',
          text: `Error resetting password: ${errorRes.data || 'Please try again.'}`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    } catch (userError) {
      Swal.fire({
        title: 'Error!',
        text: userError.message || 'Error resetting password. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
    }
    setLoading(false);
  };

  if (!isOpen) return null;

  return (
    <>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader"></div>
        </div>
      )}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-auto">
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <div className="flex space-x-4">
              <button
                onClick={() => setIsResetPassword(false)}
                className={`px-4 py-2 ${!isResetPassword ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
              >
                Edit User Status
              </button>
              <button
                onClick={() => setIsResetPassword(true)}
                className={`px-4 py-2 ${isResetPassword ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-600'}`}
              >
                Reset Password
              </button>
            </div>
            <button className="text-gray-500 hover:text-gray-700" onClick={onClose}>×</button>
          </div>

          <div className="p-4">
            {isResetPassword ? (
              <form onSubmit={handleResetPassword}>
                <p className="text-lg mb-4">Do you want to reset this user's password to the default password?</p>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Yes
                  </button>
                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="isActive" className="block text-gray-700 font-medium mb-2">
                    Is Active
                  </label>
                  <select
                    id="isActive"
                    name="isActive"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                    required
                    className="block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                  >
                    Save
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUserModal;
