import React, { useEffect, useState } from 'react';
import AddUserModal from './AddUserModal';
import AddVehicleModal from './AddVehicleModal';
import EditFormsModal from './EditFormsModal';
import EditVehicleModal from './EditVehicleModal';
import EditUserModal from './EditUserModal';
import Swal from 'sweetalert2';
import { useUser } from '../App';
import config from '../config';
import AddJotFormModal from './AddJotFormModal';

const Management = () => {
  const [data, setData] = useState({ users: [], vehicles: [], forms: [], billing: [] });
  const [keysToShow, setKeysToShow] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTable, setActiveTable] = useState('users');
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddVehicleModalOpen, setIsAddVehicleModalOpen] = useState(false);
  const [isAddJotFormModalOpen, setIsAddJotFormModalOpen] = useState(false);
  const [isEditFormsModalOpen, setIsEditFormsModalOpen] = useState(false);
  const [isEditVehicleModalOpen, setIsEditVehicleModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
  const [isPaymentSetup, setIsPaymentSetup] = useState(false);
  const { user } = useUser();
  const token = user.token;
  const [payableAmount, setPayableAmount] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      let response;
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      if (activeTable === 'users') {
        response = await fetch(`${config.API_BASE_URL}/user/getUsersbyCompany`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'vehicles') {
        response = await fetch(`${config.API_BASE_URL}/vehicle`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'forms') {
        response = await fetch(`${config.API_BASE_URL}/form/getManageForms`, {
          method: 'GET',
          headers
        });
      } else if (activeTable === 'billing') {
        response = await fetch(`${config.API_BASE_URL}/billing/getBillsbyCompany`, {
          method: 'GET',
          headers
        });
        setIsCheckboxChecked(false);
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (activeTable === 'users') {
        const processedData = data.data.map(user => ({
          ...user,
          name: `${user.firstName || ''} ${user.middleName || ''} ${user.lastName || ''}`.trim() || '',
          isActive: user.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, users: processedData }));
        setKeysToShow(['name', 'userName', 'email', 'role', 'isActive']);
      } else if (activeTable === 'vehicles') {
        const vehiclesData = data.data.vehicles.map(vehicle => ({
          ...vehicle,
          isActive: vehicle.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, vehicles: vehiclesData }));
        setKeysToShow(['vin', 'type', 'engine', 'vehicleNumber', 'description', 'isActive']);
      } else if (activeTable === 'forms') {
        const formsData = data.data.forms.map(form => ({
          ...form,
          isActive: form.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, forms: formsData }));
        setKeysToShow(['formName', 'isActive']);
      } else if (activeTable === 'billing') {
        const billingData = data.data.map(bill => ({
          ...bill,
          isBillPaid: bill.isBillPaid ? 'Yes' : 'No',
          isActive: bill.isActive ? 'Yes' : 'No'
        }));
        setData(prevData => ({ ...prevData, billing: billingData }));
        setKeysToShow(['vehiclesCount', 'usersCount', 'pricePerUser', 'pricePerVehicle', 'totalAmount', 'month', 'isBillPaid']);

        const totalPayableAmount = billingData.reduce((total, bill) => {
          if (bill.isBillPaid === 'No') {
            return total + bill.totalAmount;
          }
          return total;
        }, 0);

        setPayableAmount(totalPayableAmount);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error loading the data:', error);
      setLoading(false);
    }
  };

  const handleCheckboxChange = async (e) => {
    setIsCheckboxChecked(e.target.checked);
    if(e.target.checked)
    {
      try {
        const response = await fetch(`${config.API_BASE_URL}/billing/checkPaymentSetup`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.data) {
          Swal.fire({
            title: 'One Payment Method Added',
            text: 'Proceed to add another payment method.',
            icon: 'info',
            confirmButtonText: 'OK'
          });
        }
      } catch (error) {
        console.error('Error checking payment setup:', error);
        Swal.fire({
          title: 'Error',
          text: 'There was an error checking the payment setup.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        setIsCheckboxDisabled(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTable, token]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredData = data[activeTable].filter(item =>
    keysToShow.some(key =>
      String(item[key]).toLowerCase().includes(searchTerm)
    )
  ).filter(item => activeTable !== 'billing' || item.isActive === 'Yes');

  const handleAddUser = () => {
    fetchData();
  };

  const handleAddVehicle = () => {
    fetchData();
  };

  const handleAddJotForm = () => {
    fetchData();
  };

  const handleEditForm = (updatedForm) => {
    setData(prevData => ({
      ...prevData,
      forms: prevData.forms.map(form =>
        form.id === updatedForm.id
          ? { ...updatedForm, isActive: updatedForm.isActive ? 'Yes' : 'No' }
          : form
      )
    }));
  };

  const handleEditVehicle = (updatedVehicle) => {
    setData(prevData => ({
      ...prevData,
      vehicles: prevData.vehicles.map(vehicle =>
        vehicle.id === updatedVehicle.id
          ? { ...updatedVehicle, isActive: updatedVehicle.isActive ? 'Yes' : 'No' }
          : vehicle
      )
    }));
  };

  const handleEditUser = (updatedUser) => {
    setData(prevData => ({
      ...prevData,
      users: prevData.users.map(user =>
        user.id === updatedUser.id
          ? { ...updatedUser, isActive: updatedUser.isActive ? 'Yes' : 'No' }
          : user
      )
    }));
  };

  const openEditFormsModal = (form) => {
    setSelectedForm(form);
    setIsEditFormsModalOpen(true);
  };

  const openEditVehicleModal = (vehicle) => {
    setSelectedVehicle(vehicle);
    setIsEditVehicleModalOpen(true);
  };

  const openEditUserModal = (user) => {
    setSelectedUser(user);
    setIsEditUserModalOpen(true);
  };

  const handleAddPaymentMethod = async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/payment/createCheckoutSession`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.status === 'success') {
        window.location.href = data.url;
      } else {
        console.error('Payment session creation failed:', data);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      {/* Tabs for Users, Vehicles, Forms, Billing */}
      <div className="flex space-x-4 mb-8">
        <button
          onClick={() => setActiveTable('users')}
          className={`px-4 py-2 rounded-lg ${
            activeTable === 'users' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Users
        </button>
        <button
          onClick={() => setActiveTable('vehicles')}
          className={`px-4 py-2 rounded-lg ${
            activeTable === 'vehicles' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Vehicles
        </button>
        <button
          onClick={() => setActiveTable('forms')}
          className={`px-4 py-2 rounded-lg ${
            activeTable === 'forms' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Forms
        </button>
        <button
          onClick={() => setActiveTable('billing')}
          className={`px-4 py-2 rounded-lg ${
            activeTable === 'billing' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          Billing
        </button>
      </div>

      {/* Search and Add buttons */}
      <div className="flex justify-between items-center mb-6">
        <input
          type="text"
          className="border border-gray-300 rounded-lg py-2 px-4 w-1/3"
          placeholder={`Search ${activeTable}`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
        />

        {/* Conditional Add Button */}
        {activeTable === 'users' && (
          <button
            onClick={() => setIsAddUserModalOpen(true)}
            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Add User
          </button>
        )}
        {activeTable === 'vehicles' && (
          <button
            onClick={() => setIsAddVehicleModalOpen(true)}
            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Add Vehicle
          </button>
        )}
        {user.userRole === 'orgadmin' && activeTable === 'forms' &&  (
          <button 
            onClick={() => setIsAddJotFormModalOpen(true)} 
            className="bg-gray-700 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          >
            Add Form
          </button>
        )}
      </div>

      {/* Data Table */}
      <div className="bg-white p-6 rounded-lg shadow-lg">
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <table className="min-w-full border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                {keysToShow.map((key) => (
                  <th key={key} className="py-3 px-4 text-left text-gray-700">{key}</th>
                ))}
                {(activeTable === 'forms' || activeTable === 'vehicles' || activeTable === 'users') && <th className="py-3 px-4"></th>}
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td
                    colSpan={keysToShow.length + (activeTable === 'forms' || activeTable === 'vehicles' || activeTable === 'users' ? 1 : 0)}
                    className="text-center py-4 text-gray-500"
                  >
                    No data available
                  </td>
                </tr>
              ) : (
                filteredData.map((row, index) => (
                  <tr key={index} className="border-t hover:bg-gray-50">
                    {keysToShow.map((key) => (
                      <td key={key} className="py-3 px-4 text-gray-700">{row[key]}</td>
                    ))}
                    {(activeTable === 'forms' || activeTable === 'vehicles' || activeTable === 'users') && (
                      <td className="py-3 px-4">
                        <button
                          onClick={() => {
                            if (activeTable === 'forms') openEditFormsModal(row);
                            if (activeTable === 'vehicles') openEditVehicleModal(row);
                            if (activeTable === 'users') openEditUserModal(row);
                          }}
                          className="text-blue-500 hover:underline"
                        >
                          Edit
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>

      {/* Billing Section */}
      {activeTable === 'billing' && filteredData.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-lg mt-6">
          <div className="mb-4 text-lg font-semibold">Total Payable amount: <span className="text-gray-700">${payableAmount}</span></div>
          <div className="flex items-center space-x-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="form-checkbox text-gray-700"
                checked={isCheckboxChecked}
                disabled={isCheckboxDisabled}
                onChange={handleCheckboxChange}
              />
              <span className="text-gray-700">I certify for recurring payment</span>
            </label>
            <button
              onClick={handleAddPaymentMethod}
              disabled={!isCheckboxChecked}
              className={`bg-gray-700 text-white px-4 py-2 rounded-lg ${!isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
            >
              Add Payment Method
            </button>
          </div>
        </div>
      )}

      {/* Modals */}
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={() => setIsAddUserModalOpen(false)}
        onSave={fetchData}
      />
      <AddVehicleModal
        isOpen={isAddVehicleModalOpen}
        onClose={() => setIsAddVehicleModalOpen(false)}
        onSave={fetchData}
      />
      <AddJotFormModal
        isOpen={isAddJotFormModalOpen}
        onClose={() => setIsAddJotFormModalOpen(false)}
        onSave={handleAddJotForm}
      />
      <EditFormsModal
        isOpen={isEditFormsModalOpen}
        onClose={() => setIsEditFormsModalOpen(false)}
        onSave={handleEditForm}
        form={selectedForm}
        refreshForms={fetchData}
      />
      <EditVehicleModal
        isOpen={isEditVehicleModalOpen}
        onClose={() => setIsEditVehicleModalOpen(false)}
        onSave={handleEditVehicle}
        vehicle={selectedVehicle}
      />
      <EditUserModal
        isOpen={isEditUserModalOpen}
        onClose={() => setIsEditUserModalOpen(false)}
        onSave={handleEditUser}
        user={selectedUser}
      />
    </div>
  );
};

export default Management;
