import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from './App'; 

const ProtectedRoute = ({ children, roleRequired }) => {
    const { user } = useUser();

    // //if (!user || user.userRole !== roleRequired) {
    // if (!user || (roleRequired && user.userRole !== roleRequired)) { // changed to impliment protected routing on forms and report page
    //     return <Navigate to="/login" replace />;
    // }

    // Check if `roleRequired` is an array and user has one of the allowed roles, or a single role
    const hasAccess = Array.isArray(roleRequired) ? roleRequired.includes(user?.userRole) : user?.userRole === roleRequired;

    // If user doesn't have access, redirect to login page
    if (!user || (roleRequired && !hasAccess)) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;
