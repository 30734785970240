import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useUser } from '../App';
import config from '../config';

const Dashboard = () => {
  const { user } = useUser();
  const token = user?.token;
  const navigate = useNavigate();

  const [forms, setForms] = useState([]);
  const [loadingForms, setLoadingForms] = useState(true);
  const [noForms, setNoForms] = useState(false);
  
  const [reports, setReports] = useState([]);
  const [groupedReports, setGroupedReports] = useState({});
  const [loadingReports, setLoadingReports] = useState(true);
  const [noReports, setNoReports] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState(null);

  // Capitalize first letter of each word
  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Fetch Forms
  const fetchForms = useCallback(async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/form`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          setForms(data.data.forms);
          if (data.data.forms.length === 0) setNoForms(true);
        }
      }
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    } finally {
      setLoadingForms(false);
    }
  }, [token]);

    // Fetch Reports
    const fetchReports = useCallback(async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/report/getReportList`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success') {
            setReports(data.data.reports || []);
            groupReportsByFormName(data.data.reports);
            if (data.data.reports.length === 0) setNoReports(true);
          } else {
            Swal.fire('Error', 'Failed to fetch reports', 'error');
          }
        }
      } catch (error) {
        Swal.fire('Error', error.message, 'error');
      } finally {
        setLoadingReports(false);
      }
    }, [token]);

  useEffect(() => {
    fetchForms();
    fetchReports();
  }, [fetchForms, fetchReports]);

  const groupReportsByFormName = (reports) => {
    const grouped = reports.reduce((acc, report) => {
      const formName = report.formName || 'Unknown Form';
      acc[formName] = [...(acc[formName] || []), report];
      return acc;
    }, {});
    setGroupedReports(grouped);
  };

  const toggleGroup = (formName) => {
    setExpandedGroup(expandedGroup === formName ? null : formName);
  };

  const fetchAndDownloadReport = async (reportId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/report/downloadPdfFromS3?reportId=${reportId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const linkElement = document.createElement('a');
        linkElement.href = url;
        linkElement.setAttribute('download', 'report.pdf');
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(url);
      } else {
        Swal.fire('Error', 'Failed to download report', 'error');
      }
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };

  const handleFormClick = (form) => {
    const uId = user.userId;
    navigate(`/forms/${form.formName}`, { state: { form, uId } });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Dashboard</h2>

        {/* Forms Section */}
        <div className="space-y-12">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-gray-700">Forms</h3>
            {loadingForms ? (
              <p>Loading Forms...</p>
            ) : noForms ? (
              <p>No forms available.</p>
            ) : (
              <table className="table-auto w-full border border-collapse border-gray-300">
                <thead>
                  <tr className="text-left bg-gray-700 text-white">
                    <th className="py-2 px-4 w-3/4 border border-gray-300">Form Name</th>
                    <th className="py-2 px-4 border border-gray-300"></th>
                  </tr>
                </thead>
                <tbody>
                  {forms.map((form) => (
                    <tr key={form.id} className="hover:bg-gray-100 bg-white border border-gray-300">
                      <td className="py-2 px-4 text-gray-700 border border-gray-300">{capitalizeWords(form.formName)}</td>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        <button
                          className="bg-gray-700 text-white py-1 px-3 rounded-lg hover:bg-gray-800"
                          onClick={() => handleFormClick(form)}
                        >
                          Fill Form
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {/* Reports Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold mb-4 text-gray-700">Reports</h3>
            {loadingReports ? (
              <p>Loading Reports...</p>
            ) : noReports ? (
              <p>No reports available.</p>
            ) : (
              <table className="table-auto w-full border border-collapse border-gray-300">
                <thead>
                  <tr className="text-left bg-gray-700 text-white">
                    <th className="py-2 px-4 border border-gray-300"></th>
                    <th className="py-2 px-4 border border-gray-300">Report Name</th>
                    <th className="py-2 px-4 border border-gray-300">Created By</th>
                    <th className="py-2 px-4 border border-gray-300">Created At</th>
                    <th className="py-2 px-4 border border-gray-300">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedReports).map(([formName, reports]) => (
                    <React.Fragment key={formName}>
                      <tr className="bg-white border border-gray-300">
                        <td>
                          <button
                            className={`text-lg font-semibold transition-transform transform ${
                              expandedGroup === formName ? 'rotate-90' : ''
                            }`}
                            onClick={() => toggleGroup(formName)}
                          >
                            <svg
                              className="w-5 h-5 text-gray-700"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                              ></path>
                            </svg>
                          </button>
                        </td>
                        <td colSpan="4" className="font-bold py-2 px-4 text-gray-700 border border-gray-300">{capitalizeWords(formName)} Reports</td>
                      </tr>
                      {expandedGroup === formName && reports.map((report) => (
                        <tr key={report.id} className="hover:bg-gray-100 bg-white border border-gray-300">
                          <td></td>
                          <td className="py-2 px-4 text-gray-700 border border-gray-300">{capitalizeWords(report.reportName)}</td>
                          <td className="py-2 px-4 text-gray-700 border border-gray-300">{report.createdBy}</td>
                          <td className="py-2 px-4 text-gray-700 border border-gray-300">
                            {new Date(report.createdAt).toLocaleString()}
                          </td>
                          <td className="py-2 px-4 border border-gray-300">
                            <button
                              className="bg-gray-700 text-white py-1 px-3 rounded-lg hover:bg-gray-800"
                              onClick={() => fetchAndDownloadReport(report.id)}
                            >
                              Download
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
