import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../App';
import { useNavigate } from 'react-router-dom';
import config from '../config';

function Forms() {
  const { user } = useUser();
  const token = user.token;
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchForms = useCallback(async () => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/form`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.status === 'success') {
          setForms(data.data.forms);
        }
      } else {
        console.error('Failed to fetch forms:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchForms();
  }, [fetchForms]);

  const handleFormClick = (form) => {
    const uId = user.userId; // Added to pass uid in url param to fill jotform hidden field
    navigate(`/forms/${form.formName}`, { state: { form, uId } }); // Passing uid in URL param to fill hidden field in jotform
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  // Capitalize first letter of each word
    const capitalizeWords = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-800 mb-8">Forms</h2>

        {isLoading ? (
          <p className="text-center text-gray-600">Loading...</p>
        ) : forms.length === 0 ? (
          <p className="text-red-500 font-semibold">No Forms found for this Company. Please contact admin!</p>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-lg">

              <table className="table-auto w-full border border-collapse border-gray-300">
                <thead>
                  <tr className="text-left bg-gray-700 text-white">
                    <th className="py-2 px-4 w-3/4 border border-gray-300">Form Name</th>
                    <th className="py-2 px-4 border border-gray-300"></th>
                  </tr>
                </thead>
                <tbody>
                  {forms.map((form) => (
                    <tr key={form.id} className="hover:bg-gray-100 bg-white border border-gray-300">
                      <td className="py-2 px-4 text-gray-700 border border-gray-300">{capitalizeWords(form.formName)}</td>
                      <td className="py-2 px-4 text-center border border-gray-300">
                        <button
                          className="bg-gray-700 text-white py-1 px-3 rounded-lg hover:bg-gray-800"
                          onClick={() => handleFormClick(form)}
                        >
                          Fill Form
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        )}
      </div>
      {/* Optional back button for admins */}
      {user.userRole === 'admin' && (
        <div className="text-center mt-6">
          <button
            onClick={handleBackClick}
            className="bg-gray-700 text-white py-1 px-3 rounded-lg hover:bg-gray-800"
          >
            Back to Dashboard
          </button>
        </div>
      )}
    </div>
  );
}

export default Forms;
