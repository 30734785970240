import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App'; // Import the custom hook from App.js
import Swal from 'sweetalert2'; // For alert notifications
import config from '../config'; // Importing your API base URL from config

const OrgAdminCompanySelect = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state similar to Forms
  const navigate = useNavigate();
  const { user, login } = useUser(); // Get the user object and login function from context for changing user state once orgadmin selects company
  const token = user.token; // Get the JWT token from the user object

  useEffect(() => {
    const companyData = JSON.parse(localStorage.getItem('companyData'));
    if (companyData) {
      setCompanies(companyData);
      setIsLoading(false); // Stop loading when data is fetched
    } else {
      navigate('/login'); // Redirect to login if no company data
    }
  }, [navigate]);

  // Helper function for POST API call
  const fetchPostApi = (endpoint, jsonBody) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json'
    };

    return fetch(`${config.API_BASE_URL}` + endpoint, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: headers,
    })
    .then(res => res)
    .catch(err => err);
  };

  const handleCompanyClick = async (company) => {
    // Retrieve current 'user' object from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      // Call the selectCompany API
      const companyId = company.companyId;
      const obj = {companyId};
      const response = await fetchPostApi('/user/selectCompany', obj);

      if (response.status === 200) {
        const data = await response.json();

        // Update the selected companyId and selected companyName inside the user object
        user.companyId = company.companyId;
        user.selectedCompanyName = company.companyName;
        user.companyDefPass = company.companyDefaultPassword;
        user.token = data.token; // Update the new token

        // Save the updated user object with selected companyID and new token back to localStorage
        localStorage.setItem('user', JSON.stringify(user));
        
        // The login function is used to update the user in the global state after orgadmin selects the company to update Token and company details as per selection
        login(user);

        // Navigate to the dashboard after selecting the company and pass the company name
        navigate(`/dashboard`, { state: { companyName: company.companyName } });

        // Show success message
        Swal.fire({
            title: `Welcome to ${company.companyName}`,
            text: 'Company selected successfully.',
            icon: 'success',
            confirmButtonText: 'OK',
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: 'Failed!',
          text: errorData.message || 'Something went wrong!',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Error selecting company:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Server error. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {isLoading ? (
        <p className="text-gray-700 text-xl">Loading...</p>
      ) : companies.length === 0 ? (
        <p className="text-red-600 font-bold text-xl">No companies found. Please contact Nix Administrator!</p>
      ) : (
        <>
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Select a Company</h2>
          <table className="min-w-full table-auto border-collapse border border-gray-300 bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border border-gray-300 bg-gray-700 text-left text-m font-medium text-gray-100">
                  Company Name
                </th>
                <th className="py-2 px-4 border border-gray-300 bg-gray-700 text-left text-m font-medium text-gray-100">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.companyId} className="hover:bg-gray-50">
                  <td className="py-2 px-4 border border-gray-300 text-m text-gray-700">{company.companyName}</td>
                  <td className="py-2 px-4 border border-gray-300 text-m">
                    <button
                      onClick={() => handleCompanyClick(company)}
                      className="bg-gray-700 text-white px-3 py-1 rounded-md hover:bg-gray-800"
                    >
                      View Company
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default OrgAdminCompanySelect;