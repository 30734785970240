import './tailwind.css';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Header from './shared/Header';
import Footer from './shared/Footer';
import Registration from './components/Registration';
import UserProfile from './components/UserProfile';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import JotForms from './components/users/Jotforms';
import UnderConstruction from './UnderConstruction';
import Management from './components/Management';
import ProtectedRoute from './ProtectedRoute';
import Forms from './components/Forms';
import Reports from './components/Reports';
import HomePage from './Homepage';
import Services from './Services';
import Benefits from './Benefits';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import OrgAdminCompanySelect from './components/OrgAdminCompanySelection';

// Create a context for user state
const UserContext = createContext(null);

export const useUser = () => {
    return useContext(UserContext);
};

const DynamicFormRoute = () => {
    const location = useLocation();
    const { form } = location.state || {};

    return form ? (
        <JotForms formId={form.id} formTitle={form.formName} formUrl={form.formLink} />
    ) : (
        <Navigate to="/" />
    );
};

const TokenExpiryCheck = ({ logout }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkTokenAge = () => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                const loginTime = parseInt(localStorage.getItem('loginTime'), 10);
                const currentTime = Date.now();
                const diffDays = Math.floor((currentTime - loginTime) / (1000 * 60 * 60 * 24));
                if (diffDays > 90) {
                    Swal.fire({
                        title: 'Session Expired',
                        text: 'Your session has expired. Please log in again.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        logout();
                        navigate('/');
                    });
                }
            }
        };

        checkTokenAge();
        const intervalId = setInterval(checkTokenAge, 24 * 60 * 60 * 1000); // Check every 24 hours
        return () => clearInterval(intervalId);
    }, [logout, navigate]);

    return null;
};

function App() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

    const login = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const logout = () => {
        setUser(null);
        localStorage.clear();
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            <Router>
                <div className="App bg-gray-50 text-gray-900 font-sans">
                    <Header />
                    
                    <main className="content-wrapper mt-16"> {/* Adjust margin for fixed header */}
                        <TokenExpiryCheck logout={logout} />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/index" element={<HomePage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/register" element={<Registration />} />
                            <Route path="/userProfile" element={<ProtectedRoute roleRequired="user"><UserProfile role="user" /></ProtectedRoute>} />
                            <Route path="/adminProfile" element={<ProtectedRoute roleRequired='admin'><UserProfile role="admin" /></ProtectedRoute>} />
                            <Route path="/orgadminProfile" element={<ProtectedRoute roleRequired='orgadmin'><UserProfile role="orgadmin" /></ProtectedRoute>} />
                            <Route path="/dashboard" element={<ProtectedRoute roleRequired={['admin', 'orgadmin']}><Dashboard /></ProtectedRoute>} />
                            <Route path="/docs/contact" element={<JotForms formId="241275195641155" formTitle="General Inquiry Contact Form" formUrl="https://form.jotform.com/241275195641155" />} />
                            <Route path="/underConstruction" element={<UnderConstruction />} />
                            <Route path="/management" element={<ProtectedRoute roleRequired={['admin', 'orgadmin']}><Management /></ProtectedRoute>} />
                            <Route path="/userForms" element={<ProtectedRoute><Forms /></ProtectedRoute>} />
                            <Route path="/forms/:formName" element={<DynamicFormRoute />} />
                            <Route path="/allReports" element={<ProtectedRoute roleRequired="admin"><Reports /></ProtectedRoute>} />
                            <Route path="/orgAdmin/selectCompany" element={<ProtectedRoute roleRequired="orgadmin"><OrgAdminCompanySelect/></ProtectedRoute>} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/benefits" element={<Benefits />} />
                            <Route path="/pricing" element={<Pricing />} />
                            <Route path="/testimonials" element={<Testimonials />} />
                        </Routes>
                    </main>
    
                    <Footer />
                </div>
            </Router>
        </UserContext.Provider>
    );    
}

export default App;
