import React from 'react';
import Slider from 'react-slick'; // Import a slider library like react-slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos'; // For animations
import 'aos/dist/aos.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; // For map integration
import 'leaflet/dist/leaflet.css'; // Leaflet CSS for Map

const position = [43.65107, -79.347015]; // Example coordinates

const Homepage = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="homepage-container">
      
      {/* Hero Section with Video Background */}
      <section className="relative h-screen flex items-center justify-center">
        <video 
          className="absolute top-0 left-0 w-full h-full object-cover" 
          src="/images/hero.mp4" 
          preload="auto"
          autoPlay 
          muted 
          loop 
          playsInline // For mobile browsers to autoplay
          controls={false} // Explicitly hide controls
          style={{objectFit: 'cover'}} // Ensure video covers the section completely
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
        <div className="text-center text-white relative z-10">
          <h1 className="text-yellow-500 text-5xl md:text-7xl font-bold mb-4">Unlocking Your Data</h1>
          <p className="text-yellow-600 text-lg md:text-2xl mb-8">that's hidden in your vehicle.</p>
        </div>
      </section>


      {/* Our Services Section */}
      <section className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-12" >Our Expertise</h2>
            
            {/* Service 1 */}
            <div className="flex flex-col items-center md:flex-row md:items-start md:justify-between mb-16" data-aos="fade-left">
              <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                <img src="/images/emissions.jpeg" alt="Vehicle Emissions" className="rounded-lg shadow-lg transition-transform duration-500 hover:scale-105" />
              </div>
              <div className="w-full md:w-1/2 px-4">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Vehicle Emissions Monitoring</h3>
                <p className="text-lg text-gray-700 mb-6">
                  Keep your fleet in compliance and optimize performance with our real-time emissions tracking system. Our technology provides actionable insights that help reduce environmental impact and enhance operational efficiency.
                </p>
                <p className="text-lg text-gray-700">
                  Stay ahead with our innovative solutions that ensure regulatory compliance and boost your company's reputation.
                </p>
              </div>
            </div>
            
            {/* Service 2 */}
            <div className="flex flex-col items-center md:flex-row md:items-start md:justify-between mb-16" data-aos="fade-right">
              <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0 order-2 md:order-1">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Regulatory Compliance Reports</h3>
                <p className="text-lg text-gray-700 mb-6">
                  Automate your compliance reporting with our advanced system. We help you stay updated with changing regulations and avoid costly penalties by providing accurate, timely reports that align with industry standards.
                </p>
                <p className="text-lg text-gray-700">
                  Our customizable reports ensure you have complete visibility into your compliance status, making audits a breeze.
                </p>
              </div>
              <div className="w-full md:w-1/2 px-4 order-1 md:order-2">
                <img src="/images/reports.jpeg" alt="Regulatory Reports" className="rounded-lg shadow-lg transition-transform duration-500 hover:scale-105" />
              </div>
            </div>

            {/* Service 3 */}
            <div className="flex flex-col items-center md:flex-row md:items-start md:justify-between" data-aos="fade-left">
              <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                <img src="/images/integration.jpeg" alt="Third-Party Integration" className="rounded-lg shadow-lg transition-transform duration-500 hover:scale-105" />
              </div>
              <div className="w-full md:w-1/2 px-4">
                <h3 className="text-3xl font-semibold text-yellow-500 mb-4">Seamless Third-Party Integration</h3>
                <p className="text-lg text-gray-700 mb-6">
                  Integrate our platform with your existing systems for a smooth, unified experience. Our robust API and versatile connectors ensure that all your tools work together efficiently, streamlining your operations.
                </p>
                <p className="text-lg text-gray-700">
                  Whether it's CRM, ERP, or custom software, our solutions fit right into your workflow, enhancing productivity and data accuracy.
                </p>
              </div>
            </div>
          </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-gray-200 text-4xl font-bold mb-8">Why Choose Us</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="why-card" data-aos="fade-right">
              <h3 className="text-yellow-500 text-2xl font-semibold mb-2">Experienced Team</h3>
              <p className="text-gray-300">Our team consists of industry experts with years of experience in construction and infrastructure development.</p>
            </div>
            <div className="why-card" data-aos="fade-up">
              <h3 className="text-yellow-500 text-2xl font-semibold mb-2">State-of-the-Art Technology</h3>
              <p className="text-gray-300">We use the latest technology and tools to ensure the highest quality and efficiency in every project.</p>
            </div>
            <div className="why-card" data-aos="fade-left">
              <h3 className="text-yellow-500 text-2xl font-semibold mb-2">Sustainable Practices</h3>
              <p className="text-gray-300">We are committed to sustainable practices and environmentally friendly solutions in all our projects.</p>
            </div>
          </div>
        </div>
      </section>

{/* Partners Section */}
<section className="py-16 bg-gray-50 flex justify-center items-center flex-wrap space-x-8">
        <img src="/images/kran.png" alt="Partner 1" className="h-12 transition-transform duration-300 hover:scale-110" />
        <img src="/images/logo2.png" alt="Partner 2" className="h-12 transition-transform duration-300 hover:scale-110" />
        <img src="/images/logo3.png" alt="Partner 3" className="h-12 transition-transform duration-300 hover:scale-110" />
        <img src="/images/logo4.png" alt="Partner 4" className="h-12 transition-transform duration-300 hover:scale-110" />
        {/* Add more logos as needed */}
      </section>

      {/* Contact Us Section */}
      <section className="py-20 bg-gray-100">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Map Section */}
          <div className="map-container">
            <MapContainer center={position} zoom={13} scrollWheelZoom={false} className="h-96 rounded-lg shadow-lg" style={{zIndex:9}}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={position}>
                <Popup>
                  Our Headquarters
                </Popup>
              </Marker>
            </MapContainer>
          </div>
          
          {/* Contact Information */}
          <div className="text-center md:text-left flex flex-col justify-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-8">Get in Touch</h2>
            <p className="text-lg text-gray-600 mb-8">Have questions or need more information? Reach out to us!</p>
            <button className="bg-yellow-500 text-white px-8 py-3 rounded-full shadow-lg hover:bg-yellow-600 transition duration-300">
              Contact Us
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepage;
