import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const Registration = () => {
  // State hooks for form fields
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    companyPhone: '',
    companyAddress: '',
    city: '',
    state: '',
    zip: '',
    companyWebsite: '',
    companyEmail: '',
    userName: '',
    password: '',
    confirmPassword: '',
  });

  const [formDisabled, setFormDisabled] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const { login } = useUser(); // Get the login function from context

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      navigate('/dashboard');
    }
  }, [navigate]);

  // Handle change for form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Toggle password visibility
  const handlePasswordVisibilityToggle = () => {
    setPasswordShown(!passwordShown);
  };

  // Prepend protocol and www. to the URL if necessary
  const prependHttp = (url) => {
    if (!url) return '';
    if (!/^https?:\/\//i.test(url)) url = `https://${url}`;
    const protocolEndIndex = url.indexOf("//") + 2;
    if (!url.substring(protocolEndIndex).includes("www.")) {
      url = `${url.substring(0, protocolEndIndex)}www.${url.substring(protocolEndIndex)}`;
    }
    return url;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);

    if (formData.password !== formData.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!',
      });
      setFormDisabled(false);
      return;
    }

    const encryptedPassword = CryptoJS.SHA256(formData.password).toString();

    const obj = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      role: 'admin',
      userName: formData.userName,
      password: encryptedPassword,
      companyName: formData.companyName,
      companyUrl: prependHttp(formData.companyWebsite),
      companyPhone: formData.companyPhone,
      companyStreet: formData.companyAddress,
      companyCity: formData.city,
      companyState: formData.state,
      companyZip: formData.zip,
      companyEmail: formData.companyEmail,
    };

    const response = await fetch(`${config.API_BASE_URL}/user/registerAdmin`, {
      body: JSON.stringify(obj),
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',
      },
    });

    if (response.status === 200) {
      const res = await response.json();
      const userData = {
        userRole: res.data.user.role,
        username: res.data.user.userName,
        userId: res.data.user.id,
        companyId: res.data.user.companyId,
        firstName: res.data.user.firstName,
        lastName: res.data.user.lastName,
        token: res.token,
        companyDefPass: res.data.user.company.companyDefaultPassword // For future password reset
      };
      localStorage.setItem('user', JSON.stringify(userData));
      localStorage.setItem('loginTime', Date.now());
      login(userData);
      Swal.fire({
        title: 'Success!',
        text: 'Registration successful',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/dashboard');
        }
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: response.status === 400 ? 'Error in registration' : 'Server not working',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      setFormDisabled(false);
    }
  };

  return (
  <div className="relative flex justify-center items-start pt-32 bg-gray-100 overflow-y-auto">
  {/* Background image */}
  <div
    className="fixed mu-5 inset-0 bg-cover bg-center z-0"
    style={{ backgroundImage: 'url(/images/register.jpeg)' }} // Adjust to your background image path
  ></div>

  {/* Overlay to darken the background if necessary */}
  <div className="fixed inset-0 bg-gray-800 opacity-70 z-0"></div>

  {/* Scrollable card container */}
  <div className="relative bg-white bg-opacity-40 backdrop-blur-lg p-10 rounded-lg shadow-lg w-full max-w-4xl z-10 overflow-y-auto">
        <form id="registrationForm" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">Create Your Account</h2>
  
          {/* Personal Information Section */}
          <h6 className="text-xl font-bold text-gray-800 mb-4">Personal Information</h6>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="form-group">
              <label htmlFor="firstName" className="block text-gray-800 mb-2">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName" className="block text-gray-800 mb-2">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
  
          {/* Contact Information */}
          <div className="form-group mb-6">
            <label htmlFor="email" className="block text-gray-800 mb-2">Email Address <span className="text-red-500">*</span></label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              disabled={formDisabled}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="phoneNumber" className="block text-gray-800 mb-2">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Enter your phone number"
              value={formData.phoneNumber}
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
  
          {/* Company Information Section */}
          <h6 className="text-xl font-bold text-gray-800 mb-4">Company Information</h6>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="form-group">
              <label htmlFor="companyName" className="block text-gray-800 mb-2">Company Name <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="companyEmail" className="block text-gray-800 mb-2">Company Email <span className="text-red-500">*</span></label>
              <input
                type="email"
                id="companyEmail"
                name="companyEmail"
                placeholder="company@example.com"
                value={formData.companyEmail}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="form-group mb-6">
            <label htmlFor="companyPhone" className="block text-gray-800 mb-2">Company Phone</label>
            <input
              type="tel"
              id="companyPhone"
              name="companyPhone"
              placeholder="Company Phone Number"
              value={formData.companyPhone}
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="form-group mb-6">
            <label htmlFor="companyAddress" className="block text-gray-800 mb-2">Company Address</label>
            <input
              type="text"
              id="companyAddress"
              name="companyAddress"
              placeholder="Company Address"
              value={formData.companyAddress}
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="form-group">
              <label htmlFor="city" className="block text-gray-800 mb-2">City</label>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="state" className="block text-gray-800 mb-2">State</label>
              <input
                type="text"
                id="state"
                name="state"
                placeholder="State"
                value={formData.state}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="zip" className="block text-gray-800 mb-2">Zip</label>
              <input
                type="text"
                id="zip"
                name="zip"
                placeholder="Zip"
                value={formData.zip}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
  
          {/* Company Website */}
          <div className="form-group mb-6">
            <label htmlFor="companyWebsite" className="block text-gray-800 mb-2">Company Website</label>
            <input
              type="text"
              id="companyWebsite"
              name="companyWebsite"
              placeholder="https://"
              value={formData.companyWebsite}
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
  
          {/* Account Information Section */}
          <h6 className="text-xl font-bold text-gray-800 mb-4">Create an Account</h6>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="form-group">
              <label htmlFor="userName" className="block text-gray-800 mb-2">Username <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="userName"
                name="userName"
                placeholder="Username"
                value={formData.userName}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="block text-gray-800 mb-2">Password <span className="text-red-500">*</span></label>
              <input
                type={passwordShown ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div className="form-group mb-6">
            <label htmlFor="confirmPassword" className="block text-gray-800 mb-2">Confirm Password <span className="text-red-500">*</span></label>
            <input
              type={passwordShown ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
  
          <div className="flex items-center mb-6">
            <input
              type="checkbox"
              id="showPassword"
              checked={passwordShown}
              onChange={handlePasswordVisibilityToggle}
              className="form-checkbox h-4 w-4 text-blue-500"
            />
            <label htmlFor="showPassword" className="ml-2 text-gray-800">Show Password</label>
          </div>
  
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-6 rounded"
              disabled={formDisabled}
            >
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );  
};

export default Registration;
