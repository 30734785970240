import React, { useState, useEffect } from 'react';
import config from '../config';
import Swal from 'sweetalert2';
import { useUser } from '../App'; // Import useUser hook to get the user context

const EditFormsModal = ({ isOpen, onClose, onSave, form, refreshForms }) => {
  const { user } = useUser(); // Get the user object from context
  const token = user.token; // Get the JWT token from the user object

  const [formId, setFormId] = useState(null);
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    if (form) {
      setFormId(form.id);
      setIsActive(form.isActive === 'Yes' || form.isActive === true ? 'Yes' : 'No');
    }
  }, [form]);

  const handleIsActiveChange = (e) => {
    setIsActive(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      formId: parseInt(formId, 10), // Ensure formId is an integer
      isActive: isActive === 'Yes',
    };

    const response = await fetch(`${config.API_BASE_URL}/form/editForm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Add the JWT token in the headers
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const res = await response.json();
      Swal.fire({
        title: 'Success!',
        text: 'Form updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      onSave(res.data.forms); // Correctly update the state
      onClose();
      refreshForms(); // Fetch the latest forms data
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Error updating form. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white w-full max-w-lg rounded-lg shadow-lg p-6 relative">
        <h2 className="text-xl font-bold mb-4 text-gray-800">Edit Form</h2>
        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={onClose}>
          ×
        </button>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="isActive" className="block text-gray-700 mb-2">Is Active</label>
            <select
              id="isActive"
              name="isActive"
              value={isActive}
              onChange={handleIsActiveChange}
              className="w-full border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 border border-gray-300 rounded-lg hover:bg-gray-100 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFormsModal;
