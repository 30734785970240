import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const Testimonials = () => {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration
      easing: 'ease-in-out', // Easing style
      once: true, // Animation should happen only once
    });
  }, []);

  return (
    <div className="bg-gray-100 py-16 overflow-hidden">
      <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-800 mb-12" data-aos="fade-up">
          Client Testimonials
        </h2>

        {/* Scrolling Container */}
        <div className="relative overflow-x-hidden" data-aos="fade-up">
          <div className="flex space-x-8 animate-scroll">
            {/* Testimonial 1 */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex items-center min-w-[300px] max-w-md mx-auto">
              <div className="mr-4">
                <img className="w-16 h-16 rounded-full" src="/images/Kran+Logo.png" alt="The Kran Company Logo" />
              </div>
              <div>
                <blockquote className="text-gray-600 italic text-lg">
                  "This has made emissions reporting so much easier for us."
                </blockquote>
                <h6 className="font-medium text-gray-800 mt-4 text-base">Eric, The Kran Company</h6>
              </div>
            </div>

            {/* Testimonial 2 */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex items-center min-w-[300px] max-w-md mx-auto">
              <div className="mr-4">
                <img className="w-16 h-16 rounded-full" src="/images/kran_company.png" alt="The Kran Company Logo" />
              </div>
              <div>
                <blockquote className="text-gray-600 italic text-lg">
                  "We waste a lot of diesel on idling. This system helps us cut back significantly."
                </blockquote>
                <h6 className="font-medium text-gray-800 mt-4 text-base">Scott, The Kran Company</h6>
              </div>
            </div>

            {/* Testimonial 3 */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex items-center min-w-[300px] max-w-md mx-auto">
              <div className="mr-4">
                <img className="w-16 h-16 rounded-full" src="/images/pp_services.png" alt="P&P Services LLC Logo" />
              </div>
              <div>
                <blockquote className="text-gray-600 italic text-lg">
                  "I love knowing what’s going on in my trucks. Now I know exactly how much fuel is being used."
                </blockquote>
                <h6 className="font-medium text-gray-800 mt-4 text-base">Pepe, P&P Services LLC</h6>
              </div>
            </div>

            {/* Testimonial 4 */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex items-center min-w-[300px] max-w-md mx-auto">
              <div className="mr-4">
                <img className="w-16 h-16 rounded-full" src="/images/client_logo.png" alt="Client Logo" />
              </div>
              <div>
                <blockquote className="text-gray-600 italic text-lg">
                  "NIX has transformed the way we manage our fleet and comply with regulations. Highly recommend!"
                </blockquote>
                <h6 className="font-medium text-gray-800 mt-4 text-base">John, Fleet Management Co.</h6>
              </div>
            </div>

            {/* Testimonial 5 */}
            <div className="bg-white rounded-lg shadow-lg p-6 flex items-center min-w-[300px] max-w-md mx-auto">
              <div className="mr-4">
                <img className="w-16 h-16 rounded-full" src="/images/client_logo.png" alt="Client Logo" />
              </div>
              <div>
                <blockquote className="text-gray-600 italic text-lg">
                  "A seamless experience from installation to data management. The support team is always available."
                </blockquote>
                <h6 className="font-medium text-gray-800 mt-4 text-base">Sarah, Logistics Solutions</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
