import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App'; // Import the custom hook from App.js
import config from '../config';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

const UserProfile = ({ role }) => {
  const { user } = useUser();
  const token = user.token;
  const userName = user.username;
  const userId = user.userId;
  const companyId = user.companyId;

  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const hasFetchedProfile = useRef(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (hasFetchedProfile.current) return;
      hasFetchedProfile.current = true;

      try {
        const response = await fetch(`${config.API_BASE_URL}/user/getProfile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success') {
            const profileData = data.data.user;
            const companyData = data.data.user.company;
            setFirstName(profileData.firstName);
            setLastName(profileData.lastName);
            setEmail(profileData.email);
            setPhone(profileData.phone);
            setCompanyName(companyData.name);
            setCompanyEmail(companyData.email);
            setCompanyPhone(companyData.phone);
            setCompanyAddress(companyData.street);
            setCity(companyData.city);
            setState(companyData.state);
            setZip(companyData.zip);
            setCompanyWebsite(companyData.url);
            setUsername(profileData.userName);
            setPassword('');
            setConfirmPassword('');
          }
        } else {
          console.error('Failed to fetch profile:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userName && userId) {
      fetchProfile();
    }
  }, [user, userName, userId, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case 'firstName': setFirstName(value); break;
      case 'lastName': setLastName(value); break;
      case 'email': setEmail(value); break;
      case 'phone': setPhone(value); break;
      case 'companyPhone': if (role === 'admin') setCompanyPhone(value); break;
      case 'companyAddress': if (role === 'admin') setCompanyAddress(value); break;
      case 'city': if (role === 'admin') setCity(value); break;
      case 'state': if (role === 'admin') setState(value); break;
      case 'zip': if (role === 'admin') setZip(value); break;
      case 'companyWebsite': if (role === 'admin') setCompanyWebsite(value); break;
      case 'password': setPassword(value); break;
      case 'confirmPassword': setConfirmPassword(value); break;
      case 'username': setUsername(value); break;
      default: break;
    }
  };

  const handlePasswordVisibilityToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      Swal.fire({
        title: 'Error!',
        text: 'Passwords do not match',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
      return;
    }

    const userUpdateObj = {
      userId,
      firstName,
      lastName,
      email,
      phoneNumber: phone,
      companyId,
      companyUrl: companyWebsite,
      companyPhone,
      companyStreet: companyAddress,
      companyCity: city,
      companyState: state,
      companyZip: zip,
      userName: username,
    };

    if (password) {
      const encryptedPassword = CryptoJS.SHA256(password).toString();
      userUpdateObj.password = encryptedPassword;
    }

    const response = await fetch(`${config.API_BASE_URL}/user/editUserProfile`, {
      body: JSON.stringify(userUpdateObj),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Success!',
        text: 'Profile updated successfully',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update profile',
        icon: 'error',
        confirmButtonText: 'Try Again'
      });
      setFormDisabled(false);
    }

    setLoading(false);
  };

  if (isLoading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-8">
      {loading && (
        <div className="absolute inset-0 bg-white opacity-50 flex justify-center items-center">
          <div className="loader"></div>
        </div>
      )}

      <form id="profileForm" onSubmit={handleSubmit}>
        <h3 className="text-xl font-semibold mb-4">Personal Information</h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="firstName" className="block text-gray-700">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={firstName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-gray-700">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={lastName}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-6">
          <div>
            <label htmlFor="email" className="block text-gray-700">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={email}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-700">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={phone}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
        </div>

        <h3 className="text-xl font-semibold mt-8 mb-4">Company Information</h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="companyName" className="block text-gray-700">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm sm:text-sm"
              value={companyName}
              disabled
            />
          </div>
          <div>
            <label htmlFor="companyEmail" className="block text-gray-700">Company Email</label>
            <input
              type="email"
              id="companyEmail"
              name="companyEmail"
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm sm:text-sm"
              value={companyEmail}
              disabled
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-6">
          <div>
            <label htmlFor="companyPhone" className="block text-gray-700">Company Phone</label>
            <input
              type="tel"
              id="companyPhone"
              name="companyPhone"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={companyPhone}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
          <div>
            <label htmlFor="companyAddress" className="block text-gray-700">Company Address</label>
            <input
              type="text"
              id="companyAddress"
              name="companyAddress"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={companyAddress}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-6">
          <div>
            <label htmlFor="city" className="block text-gray-700">City</label>
            <input
              type="text"
              id="city"
              name="city"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={city}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
          <div>
            <label htmlFor="state" className="block text-gray-700">State</label>
            <input
              type="text"
              id="state"
              name="state"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={state}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-6">
          <div>
            <label htmlFor="zip" className="block text-gray-700">Zip Code</label>
            <input
              type="text"
              id="zip"
              name="zip"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={zip}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
          <div>
            <label htmlFor="companyWebsite" className="block text-gray-700">Company Website</label>
            <input
              type="text"
              id="companyWebsite"
              name="companyWebsite"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={companyWebsite}
              onChange={handleChange}
              disabled={role !== 'admin' || formDisabled}
            />
          </div>
        </div>

        <h3 className="text-xl font-semibold mt-8 mb-4">Account Information</h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="username" className="block text-gray-700">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={username}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mt-6">
          <div>
            <label htmlFor="password" className="block text-gray-700">New Password</label>
            <input
              type={passwordShown ? 'text' : 'password'}
              id="password"
              name="password"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={password}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
          <div>
            <label htmlFor="confirmPassword" className="block text-gray-700">Confirm New Password</label>
            <input
              type={passwordShown ? 'text' : 'password'}
              id="confirmPassword"
              name="confirmPassword"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={confirmPassword}
              onChange={handleChange}
              disabled={formDisabled}
            />
          </div>
        </div>

        <div className="flex items-center mt-6">
          <input
            type="checkbox"
            id="showPassword"
            checked={passwordShown}
            onChange={handlePasswordVisibilityToggle}
            className="mr-2"
          />
          <label htmlFor="showPassword" className="text-gray-700">Show Password</label>
        </div>

        <div className="flex justify-between mt-8">
          <button
            type="button"
            onClick={() => navigate('/dashboard')}
            className="py-2 px-4 bg-gray-600 text-white rounded-md hover:bg-gray-700"
            disabled={formDisabled}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            disabled={formDisabled}
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
