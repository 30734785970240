import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { ParallaxProvider, } from 'react-scroll-parallax';

const Benefits = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <ParallaxProvider>
      <div className="bg-gray-100 py-16">
        {/* Title Section */}
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl md:text-5xl font-bold text-center text-gray-800 mb-16">
            Benefits of Using NIX
          </h2>

          {/* Benefits Section */}
          <div className="space-y-24">
            {/* Benefit 1 */}
            <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-right">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/Device.png"
                  alt="Easy Setup"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-left">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">Easy Setup</h3>
                <p className="text-lg text-gray-700">
                  Install in less than 5 minutes and get started instantly. Our hassle-free process ensures that you can focus on what matters.
                </p>
              </div>
            </div>

            {/* Benefit 2 */}
            <div className="flex flex-col md:flex-row-reverse items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-left">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/requirements.jpeg"
                  alt="Meets All Requirements"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-right">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">Meets All Requirements</h3>
                <p className="text-lg text-gray-700">
                  Provides emissions data in the exact format regulators need. Ensure full compliance without worrying about formatting or manual intervention.
                </p>
              </div>
            </div>

            {/* Benefit 3 */}
            <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-right">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/analytics.jpeg"
                  alt="Automatic Reporting"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-left">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">Automatic Reporting</h3>
                <p className="text-lg text-gray-700">
                  Automatically receive detailed reports, making compliance effortless and management simpler.
                </p>
              </div>
            </div>

            {/* Benefit 4 */}
            <div className="flex flex-col md:flex-row-reverse items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-left">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/secure_data.jpeg"
                  alt="Secure Data"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-right">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">Secure Data Management</h3>
                <p className="text-lg text-gray-700">
                  We ensure that all your data is securely stored and managed, giving you peace of mind and total control over your information.
                </p>
              </div>
            </div>

            {/* Benefit 5 */}
            <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-right">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/seemless_integration.jpeg"
                  alt="Seamless Integration"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-left">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">Seamless Integration</h3>
                <p className="text-lg text-gray-700">
                  Easily integrate with your existing systems and third-party tools to enhance functionality and streamline operations.
                </p>
              </div>
            </div>

            {/* Benefit 6 */}
            <div className="flex flex-col md:flex-row-reverse items-center space-y-8 md:space-y-0 md:space-x-12">
              <div className="md:w-1/2 flex justify-center" data-aos="fade-left">
                <img
                  className="w-full max-w-sm h-auto rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
                  src="/images/customer_support.jpeg"
                  alt="Customer Support"
                />
              </div>
              <div className="md:w-1/2" data-aos="fade-right">
                <h3 className="text-3xl font-semibold text-gray-800 mb-4">24/7 Customer Support</h3>
                <p className="text-lg text-gray-700">
                  Our dedicated support team is available around the clock to assist you with any queries or issues you may have.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ParallaxProvider>
  );
};

export default Benefits;
