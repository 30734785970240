import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../App'; // Import the custom hook from App.js

function Header() {
  const { user, logout } = useUser(); // Get the user and logout function from context
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State for mobile menu
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false); // State for desktop dropdown
  const navigate = useNavigate();
  const location = useLocation();
  const profileDropdownRef = useRef(null); // Ref for profile dropdown
  const mobileMenuRef = useRef(null); // Ref for mobile menu



  const isOrgAdminSelectCompany = location.pathname === '/orgAdmin/selectCompany';

  const handleSignOut = () => {
    logout(); // Call the logout function from context
    navigate("/"); // Redirect to home page after logging out
    setMobileMenuOpen(false); // Close the mobile menu after logging out
    setProfileDropdownOpen(false); // Close desktop dropdown
  };

  const handleMobileMenuToggle = (e) => {
    e.stopPropagation(); // Prevent immediate closing
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleProfileDropdownToggle = (e) => {
    e.stopPropagation(); // Prevent immediate closing
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const handleSignIn = () => {
    navigate("/login");
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  const handleSignUp = () => {
    navigate("/register");
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };

  // Close the profile dropdown when clicking outside (for desktop)
  const handleProfileOutsideClick = (event) => {
    if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
      setProfileDropdownOpen(false);
    }
  };

  // Close the mobile menu when clicking outside (for mobile)
  const handleMobileOutsideClick = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
      setMobileMenuOpen(false);
    }
  };

  // Add event listeners for profile dropdown and mobile menu separately
  useEffect(() => {
    if (profileDropdownOpen) {
      document.addEventListener('click', handleProfileOutsideClick);
    } else {
      document.removeEventListener('click', handleProfileOutsideClick);
    }

    if (mobileMenuOpen) {
      document.addEventListener('click', handleMobileOutsideClick);
    } else {
      document.removeEventListener('click', handleMobileOutsideClick);
    }

    // Cleanup event listeners when component unmounts
    return () => {
      document.removeEventListener('click', handleProfileOutsideClick);
      document.removeEventListener('click', handleMobileOutsideClick);
    };
  }, [profileDropdownOpen, mobileMenuOpen]);


  // Function to get profile path based on role
  const getProfilePath = (role) => {
    switch (role) {
      case 'admin':
        return "/adminProfile";
      case 'user':
        return "/userProfile";
        case 'orgadmin':
          return "/orgadminProfile";
      default:
        return "/userProfile";
    }
  };
  return (
    <header className="w-full bg-gray-800 text-gray-100 shadow-lg fixed top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-4">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/">
            <img src="/images/Cleaned_Nix_Logo.png" alt="Nix Logo" className="bg-white p-1 rounded-full h-14 w-18 mr-4 transition-all duration-300 transform hover:scale-110 shadow-lg" />
          </Link>
        </div>

        {/* Main Navigation */}
        {!isOrgAdminSelectCompany && !user && (
          <nav className="hidden md:flex space-x-8 justify-center flex-1">
            <Link to="/" className="text-m text-gray-100 hover:text-yellow-500">HOME</Link>
            <Link to="/services" className="text-m text-gray-100 hover:text-yellow-500">SERVICES</Link>
            <Link to="/benefits" className="text-m text-gray-100 hover:text-yellow-500">BENEFITS</Link>
            <Link to="/pricing" className="text-m text-gray-100 hover:text-yellow-500">PRICING</Link>
            <Link to="/testimonials" className="text-m text-gray-100 hover:text-yellow-500">TESTIMONIALS</Link>
          </nav>
        )}

        {/* Signed-in Navigation */}
        {!isOrgAdminSelectCompany && user && (
          <nav className="hidden md:flex mr-8 space-x-8 ml-auto">
            {user.userRole === 'user' && (
              <Link to="/dashboard" className="text-m text-gray-100 hover:text-yellow-500">FORMS</Link>
            )}
            {user.userRole === 'admin' && (
              <>
                <Link to="/dashboard" className="text-m text-gray-100 hover:text-yellow-500">DASHBOARD</Link>
                <Link to="/management" className="text-m text-gray-100 hover:text-yellow-500">MANAGEMENT</Link>
              </>
            )}
            {user.userRole === 'orgadmin' && (
              <>
                <Link to="/dashboard" className="text-m text-gray-100 hover:text-yellow-500">DASHBOARD</Link>
                <Link to="/management" className="text-m text-gray-100 hover:text-yellow-500">MANAGEMENT</Link>
                <Link to="/orgAdmin/selectCompany" className="text-m text-gray-100 hover:text-yellow-500">COMPANY LIST</Link>
              </>
            )}
          </nav>
        )}

        {/* User Actions */}
        <div className="hidden md:flex items-center space-x-4">
          {!user ? (
            <>
              <button className="bg-gray-100 hover:bg-gray-200 text-blue-900 py-2 px-4 rounded-full" style={{ textAlign:"left", textTransform:"uppercase"}} onClick={handleSignIn}>
                Sign In
              </button>
              <button className="bg-yellow-500 hover:bg-yellow-600 text-gray-100 py-2 px-4 rounded-full" style={{ textAlign:"left", textTransform:"uppercase"}} onClick={handleSignUp}>
                Register
              </button>
            </>
          ) : (
            <div className="relative">
              <button 
                className="bg-white text-gray-700 py-2 px-6 rounded-full hover:text-yellow-500" 
                onClick={handleProfileDropdownToggle}
              >
                {user.username}
              </button>

              {/* Desktop Profile Dropdown */}
              {profileDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-md shadow-lg z-20"  ref={profileDropdownRef}>
                <ul className="py-1">
                {!isOrgAdminSelectCompany && (
                  <li><Link to={getProfilePath(user.userRole)} className="block px-4 py-2 hover:bg-gray-100 hover:text-yellow-500">Profile</Link></li>
                )}
                  <li><Link to="/docs/contact" className="block px-4 py-2 hover:bg-gray-100 hover:text-yellow-500">Contact Us</Link></li>
                  {user.userRole === 'orgadmin' && (
                    <li><Link to="/orgAdmin/selectCompany" className="block px-4 py-2 hover:bg-gray-100 hover:text-yellow-500">Company List</Link></li>
                  )}
                  <li>
                    <button className="block w-full text-left px-4 py-2 hover:bg-gray-100 hover:text-yellow-500 font-bold"  onClick={handleSignOut}>Sign Out</button>
                  </li>
                  <li className="px-4 py-2 bg-gray-200 text-gray-700 text-sm italic">
                    Logged in as: <span className="font-semibold">{user.userRole === 'orgadmin' ? 'OrgAdmin' : user.userRole.charAt(0).toUpperCase() + user.userRole.slice(1)}</span>
                  </li>
                </ul>
              </div>
              )}
            </div>
          )}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button className="text-gray-100 focus:outline-none" onClick={handleMobileMenuToggle}>
            {mobileMenuOpen ? '✕' : '☰'}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {mobileMenuOpen && (
        <div className="md:hidden bg-gray-800 text-gray-100 px-4 py-6 w-full z-10" ref={mobileMenuRef}>
          {!user && (
            <nav className="flex flex-col space-y-4 px-4">
              <Link to="/" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>HOME</Link>
              <Link to="/services" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>SERVICES</Link>
              <Link to="/benefits" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>BENEFITS</Link>
              <Link to="/pricing" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>PRICING</Link>
              <Link to="/testimonials" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>TESTIMONIALS</Link>
              <button className="text-lg text-white" style={{ textAlign:"left", textTransform:"uppercase"}} onClick={handleSignIn}>Sign In</button>
              <button className="text-lg text-white" style={{ textAlign:"left", textTransform:"uppercase"}} onClick={handleSignUp}>Register</button>
            </nav>
          )}

          {user && (
            <nav className="flex flex-col space-y-4 px-4">
              {user.userRole === 'user' && (
                  <>
                <Link to={getProfilePath(user.userRole)} className="text-lg text-white">PROFILE</Link>
                <Link to="/userForms" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>FORMS</Link>
                  </>
              )}
              {user.userRole === 'admin' && (
                <>
                
                  <Link to={getProfilePath(user.userRole)} className="text-lg text-white">PROFILE</Link>
                  <Link to="/dashboard" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>DASHBOARD</Link>
                  <Link to="/management" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>MANAGEMENT</Link>
                </>
              )}
              {user.userRole === 'orgadmin' && (
                <>
                {!isOrgAdminSelectCompany && (
                  <>
                  <Link to={getProfilePath(user.userRole)} className="text-lg text-white">PROFILE</Link>
                  <Link to="/dashboard" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>DASHBOARD</Link>
                  <Link to="/management" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>MANAGEMENT</Link>
                  </>
                )}
                  <Link to="/orgAdmin/selectCompany" className="text-lg text-white" onClick={() => handleMobileMenuToggle(false)}>COMPANY LIST</Link>
                </>
              )}
              <button className="text-lg text-white" style={{ textAlign:"left", textTransform:"uppercase"}} onClick={handleSignOut}>Sign Out</button>
            </nav>
          )}
        </div>
      )}
    </header>
  );
}

export default Header;
