import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useUser } from '../App';
import config from '../config';

function Reports() {
  const navigate = useNavigate();
  const { user } = useUser();
  const token = user?.token;

  const [reports, setReports] = useState([]);
  const [groupedReports, setGroupedReports] = useState({});
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/report/getReportList`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === 'success') {
            setReports(data.data.reports || []);
            groupReportsByFormName(data.data.reports);
            if (data.data.reports.length === 0) {
              setNoData(true);
            }
          } else {
            console.error('Failed to fetch reports:', response.statusText);
            Swal.fire('Error', 'Failed to fetch reports: ' + response.statusText, 'error');
          }
        } else {
          console.error('Failed to fetch reports:', response.statusText);
          Swal.fire('Error', 'Failed to fetch reports: ' + response.statusText, 'error');
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
        Swal.fire('Error', 'Error fetching reports: ' + error.message, 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [token]);

  const groupReportsByFormName = (reports) => {
    const grouped = reports.reduce((acc, report) => {
      const formName = report.formName || 'Unknown Form'; // Handle null formName
      acc[formName] = [...(acc[formName] || []), report];
      return acc;
    }, {});
    setGroupedReports(grouped);
  };

  const toggleGroup = (formName) => {
    setExpandedGroup(expandedGroup === formName ? null : formName);
  };

  const handleBackClick = () => {
    navigate('/reportsHome');
  };

  const fetchAndDownloadReport = async (reportId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/report/downloadPdfFromS3?reportId=${reportId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/pdf', // Change to expect a PDF
          'Authorization': `Bearer ${token}`
        }
      });
  
      if (response.ok) {
        const blob = await response.blob(); // Process as blob instead of JSON
        const url = window.URL.createObjectURL(blob);
        const linkElement = document.createElement('a');
        linkElement.href = url;
        linkElement.setAttribute('download', 'report.pdf'); // Ensure the download attribute is set with a filename
        document.body.appendChild(linkElement);
        linkElement.click();
        window.URL.revokeObjectURL(url); // Clean up the URL object
        document.body.removeChild(linkElement);
      } else {
        Swal.fire('Error', 'Failed to fetch report link: ' + response.statusText, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'Error fetching report link: ' + error.message, 'error');
    }
  };  

  return (
    <div className="report-container">
      {loading ? (
        <div className="loading-modal">
          <div className="loading-spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <h4>All Reports</h4>
          {noData ? (
            <p>No reports to show...</p>
          ) : (
            <div className="table-wrapper">
              <table className="report-table">
                <thead>
                  <tr>
                    <th></th> {/* Empty header for the button column */}
                    <th>Report Name</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Download</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(groupedReports).map(([formName, reports]) => (
                    <>
                      <tr key={formName} className="group-header">
                        <td>
                          <div className="toggle-cell" onClick={() => toggleGroup(formName)}>
                            {expandedGroup === formName ? '-' : '+'}
                          </div>
                        </td>
                        <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'left' }}>
                          {formName} Reports
                        </td>
                      </tr>
                      {expandedGroup === formName && reports.map(report => (
                        <tr key={report.id}>
                          <td></td> {}
                          <td>{report.reportName}</td>
                          <td>{report.createdBy}</td>
                          <td>{new Date(report.createdAt).toLocaleString()}</td>
                          <td>
                            <button onClick={() => fetchAndDownloadReport(report.id)} className="download-button">
                              Download
                            </button>
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
      <button className="jotFormBackButton" onClick={handleBackClick}>
        Go Back
      </button>
    </div>
  );
}

export default Reports;
