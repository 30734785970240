import React, { useState, useEffect } from 'react';
import config from '../config';
import Swal from 'sweetalert2';
import { useUser } from '../App';

const EditVehicleModal = ({ isOpen, onClose, onSave, vehicle }) => {
  const { user } = useUser();
  const token = user.token;

  const [vehicleId, setVehicleId] = useState(null);
  const [vin, setVin] = useState('');
  const [type, setType] = useState('');
  const [engine, setEngine] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    if (vehicle) {
      setVehicleId(vehicle.id);
      setVin(vehicle.vin || '');
      setType(vehicle.type || '');
      setEngine(vehicle.engine || '');
      setVehicleNumber(vehicle.vehicleNumber || '');
      setDescription(vehicle.description || '');
      setIsActive(vehicle.isActive === 'Yes' ? 'Yes' : 'No');
    }
  }, [vehicle]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      id: vehicleId,
      vin,
      type,
      engine,
      vehicleNumber,
      description,
      isActive: isActive === 'Yes',
    };

    const response = await fetch(`${config.API_BASE_URL}/vehicle/editVehicle`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const res = await response.json();
      Swal.fire({
        title: 'Success!',
        text: 'Vehicle updated successfully!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
      onSave({ ...vehicle, vin, type, engine, vehicleNumber, description, isActive: isActive === 'Yes' });
      onClose();
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Error updating vehicle. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-8 w-full max-w-md relative">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 className="text-2xl font-bold mb-4">Edit Vehicle</h2>
        <form onSubmit={handleSubmit} className="scrollbehaviour">
          <div className="mb-4">
            <label htmlFor="vin" className="block text-gray-700">VIN</label>
            <input
              type="text"
              id="vin"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="type" className="block text-gray-700">Type</label>
            <input
              type="text"
              id="type"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="engine" className="block text-gray-700">Engine</label>
            <input
              type="text"
              id="engine"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={engine}
              onChange={(e) => setEngine(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="vehicleNumber" className="block text-gray-700">Vehicle Number</label>
            <input
              type="text"
              id="vehicleNumber"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block text-gray-700">Description</label>
            <input
              type="text"
              id="description"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="isActive" className="block text-gray-700">Is Active</label>
            <select
              id="isActive"
              className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
              value={isActive}
              onChange={(e) => setIsActive(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditVehicleModal;
