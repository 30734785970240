import React, { useState, useEffect } from 'react';
import { useUser } from '../App';
import config from '../config';
import Swal from 'sweetalert2';

const AddVehicleModal = ({ isOpen, onClose, onSave }) => {
  const { user } = useUser();
  const token = user.token;
  const companyId = user.companyId;

  const [vin, setVin] = useState('');
  const [type, setType] = useState('');
  const [engine, setEngine] = useState('');
  const [vehicleNumber, setVehicleNumber] = useState('');
  const [description, setDescription] = useState('');
  const [formDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setVin('');
      setType('');
      setEngine('');
      setVehicleNumber('');
      setDescription('');
      setFormDisabled(false);
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'vin') setVin(value);
    if (name === 'type') setType(value);
    if (name === 'engine') setEngine(value);
    if (name === 'vehicleNumber') setVehicleNumber(value);
    if (name === 'description') setDescription(value);
  };

  const fetchPostApi = (endpoint, jsonBody) => {
    return fetch(`${config.API_BASE_URL}${endpoint}`, {
      body: JSON.stringify(jsonBody),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async (res) => {
      const text = await res.text();
      try {
        const json = JSON.parse(text);
        if (!res.ok) {
          throw json;
        }
        return json;
      } catch (error) {
        if (!res.ok) {
          const errorMessage = text.match(/<pre>(.*?)<\/pre>/s)?.[1]?.split('<br>')[0] || 'Error saving vehicle. Please try again.';
          throw new Error(errorMessage);
        }
        throw error;
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);

    const newVehicle = { vin, type, engine, companyId, vehicleNumber, description };
    try {
      const res = await fetchPostApi('/vehicle/addVehicle', newVehicle);
      Swal.fire({
        title: 'Success!',
        text: 'Vehicle added successfully!',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          onSave();
          onClose();
        }
      });
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: error.message || 'Error saving vehicle. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      setFormDisabled(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4">Add Vehicle</h2>
         <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="vin" className="block text-gray-700">VIN</label>
                <input
                  type="text"
                  id="vin"
                  name="vin"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                  value={vin}
                  onChange={handleChange}
                  disabled={formDisabled}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="type" className="block text-gray-700">Type</label>
                <input
                  type="text"
                  id="type"
                  name="type"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                  value={type}
                  onChange={handleChange}
                  disabled={formDisabled}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="engine" className="block text-gray-700">Engine</label>
              <input
                type="text"
                id="engine"
                name="engine"
                className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                value={engine}
                onChange={handleChange}
                disabled={formDisabled}
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="vehicleNumber" className="block text-gray-700">Vehicle Number</label>
                <input
                  type="text"
                  id="vehicleNumber"
                  name="vehicleNumber"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                  value={vehicleNumber}
                  onChange={handleChange}
                  disabled={formDisabled}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="description" className="block text-gray-700">Description</label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 focus:ring focus:ring-blue-200"
                  value={description}
                  onChange={handleChange}
                  disabled={formDisabled}
                  required
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                disabled={formDisabled}
              >
                CANCEL
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                disabled={formDisabled}
              >
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddVehicleModal;
