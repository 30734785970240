import React, { useState, useEffect } from 'react';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'tailwindcss/tailwind.css';

const Login = () => {
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const navigate = useNavigate();
  const { login } = useUser(); // Get the login function from context

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (user.userRole === 'admin') {
        navigate('/dashboard');
      } else {
        navigate('/userForms');
      }
    }
  }, [navigate]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'username') setUsername(value);
      if (name === 'password') setPassword(value);
    }

  const handlePasswordVisibilityToggle = () => {
      setPasswordShown(!passwordShown);
  }

  const fetchPostApi = (endpoint, jsonBody) => {
      const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
      };

      return fetch(`${config.API_BASE_URL}` + endpoint, {
          body: JSON.stringify(jsonBody),
          mode: 'cors',
          method: 'POST',
          headers: headers
      })
      .then(res => res)
      .catch(err => err);
  }

  const handleSubmit = async (e) => {
      e.preventDefault();
      setFormDisabled(true);

      // Encrypt the password before sending it
      const encryptedPassword = CryptoJS.SHA256(password).toString();

      const obj = { userName, password: encryptedPassword };
      const response = await fetchPostApi('/user/signin', obj);
      if (response.status === 200) {
          const res = await response.json();
          Swal.fire({
              title: 'Success!',
              text: 'User Login Successful',
              icon: 'success',
              confirmButtonText: 'OK'
          }).then((result) => {
              if (result.isConfirmed) {
                  const userData = {
                      userRole: res.data.user.role,
                      username: res.data.user.userName,
                      userId: res.data.user.id,
                      companyId: res.data.user.companyId,
                      firstName: res.data.user.firstName,
                      lastName: res.data.user.lastName,
                      token: res.token, // Store the JWT token received from the backend in userData
                      companyDefPass: res.data.user.companyDefaultPassword // added for reset to def password by admin
                  };
                  // console.log("Token", res.token);
                  // console.log("EncPAss", encryptedPassword);
                  // console.log("Comp data", res.data.companyData);
                  localStorage.setItem('user', JSON.stringify(userData));
                  localStorage.setItem('loginTime', Date.now()); // Setting login time in milliseconds
                  login(userData); // Call the login function from context

                  // For new OrgAdmin - Check if OrgAdmin and has multiple companies
                  if (res.data.user.role === 'orgadmin') {
                  // OrgAdmin with multiple companies
                      localStorage.setItem('companyData', JSON.stringify(res.data.companyData));
                      // console.log("Local Storage All Data: ", localStorage);
                      navigate('/orgAdmin/selectCompany'); // Redirect to new OrgAdmin page
                  } else {
                  // Redirect based on user role
                  if (userData.userRole === 'admin') {
                      // console.log("ELSE TOO");
                      // console.log("Local Storage All Data: ", localStorage);
                      navigate('/dashboard');
                  } else {
                      navigate('/userForms');
                  }
              }
          }
        });
      } else {
          // Handle different server responses
          if (response.status === 401) {
              Swal.fire({
                  title: 'Failed!',
                  text: "Username or password is incorrect",
                  icon: 'error',
                  confirmButtonText: 'OK'
              });
          }
          else if (response.status === 402) {
              Swal.fire({
                  title: 'Failed!',
                  text: "This account is Disabled! Please contact Admin!",
                  icon: 'error',
                  confirmButtonText: 'OK'
              });
          }
          else if (response.status === 403) {
              Swal.fire({
                  title: 'Failed!',
                  text: "This account is not a part of any company! Please Contact Admin!",
                  icon: 'error',
                  confirmButtonText: 'OK'
              });
          }else {
              Swal.fire({
                  title: 'Error!',
                  text: "Server not working",
                  icon: 'error',
                  confirmButtonText: 'OK'
              });
          }
          setFormDisabled(false);
      }
  }

    return (
      <div className="flex justify-center items-center h-screen bg-gray-100 relative overflow-hidden">
        {/* Background Image */}
        <div 
          className="absolute w-full h-full bg-cover bg-center" 
          style={{ backgroundImage: 'url(/images/signin.jpeg)' }}
        ></div>
      
        {/* Overlay */}
        <div className="absolute w-full h-full bg-gray-800 opacity-80"></div>
        
        <div className="relative bg-white bg-opacity-40 backdrop-blur-lg p-8 rounded-lg shadow-lg w-full max-w-md z-10">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Login to Your Account</h2>
          <form id="loginForm" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm text-gray-800 mb-2">Username <span className="text-red-500">*</span></label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter your username"
                value={userName}
                onChange={handleChange}
                disabled={formDisabled}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm text-gray-800 mb-2">Password <span className="text-red-500">*</span></label>
              <div className="relative">
                <input
                  type={passwordShown ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={handleChange}
                  disabled={formDisabled}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <span
                  onClick={handlePasswordVisibilityToggle}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                >
                  {passwordShown ? (
                    <svg className="h-6 w-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-5.523 0-10-4.477-10-10 0-.64.06-1.265.175-1.875m16.575.075a10.05 10.05 0 011.25 2.825M3.12 3.12l17.76 17.76"></path>
                    </svg>
                  ) : (
                    <svg className="h-6 w-6 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3l18 18M3 3v1m0-1h1m14.79 12.141a10.96 10.96 0 003.208-4.61A9.6 9.6 0 0012 7a9.6 9.6 0 00-8.209 4.531A10.954 10.954 0 003 12.97M12 19a9.6 9.6 0 00.874-4.014m-.874 4.014h1m0 0a10.96 10.96 0 01-.874-4.014"></path>
                    </svg>
                  )}
                </span>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="showPassword" className="inline-flex items-center text-gray-600">
              <input
                type="checkbox"
                id="showPassword"
                checked={passwordShown}
                onChange={handlePasswordVisibilityToggle}
                className="form-checkbox h-4 w-4 text-blue-500"
              />
              <span className="ml-2">Show Password</span>
            </label>
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300"
            disabled={formDisabled}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );  
}

export default Login;
